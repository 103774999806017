import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Suspense } from 'react';

import {
  useI18n,
  LanguageSwitcher,
  PushSubscriber,
  ChatContext,
  OrderDraftsContext,
} from '@echtech/iron-core';
import Menu from '../components/Menu/Menu';

import ironRoutes from '../routes';

import FallbackPage from './FallbackPage';
import { useCurrentContact } from '@echtech/iron-core';

export const ContactApp = (): JSX.Element => {
  const contact = useCurrentContact();
  const { lang } = useI18n();

  if (!contact?.email) throw new Error('No email from user!');

  return (
    <IonApp>
      <PushSubscriber contactId={contact.id} />
      <LanguageSwitcher supplier={contact.supplier} />
      <IonReactRouter>
        {/* <AppUrlOpenListener /> */}
        <IonSplitPane contentId="main">
          <Menu key={`menu-${lang}`} />
          <Suspense fallback={<FallbackPage />}>
            <OrderDraftsContext>
              <ChatContext supplierId={contact.supplier.id}>
                <IonRouterOutlet id="main">{ironRoutes}</IonRouterOutlet>
              </ChatContext>
            </OrderDraftsContext>
          </Suspense>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default ContactApp;
